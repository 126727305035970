import { Lang } from "./Lang";


class Hungary extends Lang {

    constructor(){
        super("hu")
        this.vals = [
            // default entries
            {"": ""},
        ]
    }
}

export { Hungary }