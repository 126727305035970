import { ICONS } from '../../../Constants/icons';
import { controllers } from '../../../Controllers/Controllers';
import { UPDATE } from '../../../DataTypes/UPDATE';
import { ImageView } from '../ImageView';
import { LangItem } from '../LangItem';
import { UpdateComponent } from '../UpdateComponent';

class AlertView extends UpdateComponent {
    constructor(props:any){
        super(props)
        this.type = UPDATE.ALERT
    }
    hideAlert(){
        controllers().alert.hide()
    }
    render(){
        const {type, message, title, visible} = controllers().alert.all;
        const visibly = !visible && 'hidden';
        // @ts-ignore
        const icon = ICONS[type]
        return <div className={`helper-wrapper helper-wrapper-bottom ${visibly}`}>
            <div className="helper-wrapper-box rgb03">
                <div className={`alert ${type}`}>
                    <div className="alert-box">
                        
                        {title && <div className="alert-title">
                            <div className="alert-title-box">
                                <LangItem for={title}/>
                            </div>
                        </div>}

                        <div className="alert-container-box">
                            {type && <div className="alert-type-image">
                                <ImageView source={icon} />
                            </div>}
                            
                            <div className="alert-content">
                                <div className="alert-content-box">
                                    <LangItem for={message}/>
                                </div>
                            </div>

                            <div className="alert-btns">
                                <div className="alert-btns-box">
                                    {/* <LButton 
                                        title="Ok" 
                                        // CustomImage={ICONS.success} 
                                        customStyle="text-icon-button" 
                                        onClick={this.hideAlert} 
                                        type="suit"
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export { AlertView }