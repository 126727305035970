import { Model } from "../Components/Models/Model"
import { coreupdate } from "../Constants/Actions"
import { langs } from "../DataTypes/languageTypes"
import { UPDATE } from "../DataTypes/UPDATE"
import { controllers } from "./Controllers"
import { English } from "./Languages/English"
import { Hungary } from "./Languages/Hungary"
import { Lang } from "./Languages/Lang"


class LanguageController extends Model {

    private _languages: Array<Lang>
    private _language: langs
    private _isMobile: boolean

    private _defaultLanguage: langs = "en"
    constructor(){
        super();
        this._languages = [
            new English(),
            new Hungary()
        ]
        this._isMobile = false
        this._language = this._defaultLanguage
        this.setLanguage(this._defaultLanguage, true)
    }

    get isMobile() {
        return this._isMobile
    }

    setIsMobile = (bool: boolean) => {
        if(this._isMobile === bool){
            return
        }
        this._isMobile = bool
        this.updateMe()
    }

    updateProvider = () => {
        coreupdate(UPDATE.LANG)
    }

    get language(){
        return this._language
    }

    setLanguage = (lang: langs, first: boolean = false) => {
        if(this._language === lang && !first){
            return
        }
        this._language = lang
        this.setSelected(lang)
        if(!first){
            this.updateProvider()
        }
    }

    setSelected = ( name : langs) => {
        this._languages.forEach(l => {
            l.setSelected(l.name === name)
        })
        this.updateMe()
    }

    toggleSelected = (name: langs) => {
        this._languages.forEach(l=> {
            l.setSelected(l.name !== name)
        })
        const newLang = this._languages.find(l=>l.selected)?.name||"en"
        this._language = newLang
        this.updateMe()
        this.updateProvider()
        controllers().auth.saveLanguage()
    }

    get selectedLanguage(){
        return this._language
    }

    get lang(){
        return this._languages.find(l=>l.name === this._language)
    }

    get langs(){
        return this._languages
    }
}

export { LanguageController }