import { HandleTask } from "../HandleTask/HandleTask";

class ScrollModel {
    private _scrollRef: any;
    private _customListener: (e:any)=>void|null
    private _scrollableController: boolean;
    private _scrollable: boolean; 
    private _handler: HandleTask;

    constructor(scrollableController: boolean = true){
        this._scrollRef = null
        this._scrollableController = scrollableController
        this._scrollable = true
        this._customListener = ()=>null
        this._handler = new HandleTask()
    }

    get handler(){
        return this._handler
    }

    get scrollableController(){
        return this._scrollableController
    }

    makeScrollable = (bool: boolean) => {
        if(this._scrollable === bool){
            return
        }
        this._scrollable = bool
        if(this._scrollRef){
            this._scrollRef.style.overflow = bool ? 'auto' : 'hidden'
        }
    }

    get scrollRef(){
        return this._scrollRef
    }

    set = (ref: any) => {
        this._scrollRef = ref
        this._handler.do()
    }


    onScrollEvent = (e: any) => {

    }

    addListenersToScroll = () => {
        this._scrollRef.addEventListener('scroll', this.onScrollEvent)
    }

    removeListenersToScroll = () => {
        this._scrollRef.removeEventListener('scroll', this.onScrollEvent)
    }

    customListenersToScroll = (custom: (e: any)=>void) => {
        this._customListener = custom
    }

    makeCustomListeners = () => {
        if(this._customListener(null)==null){
            return
        }
        if(this._scrollRef == null){
            return
        }
        this._scrollRef.addEventListener('scroll', this._customListener)
    }
    removeCustomListeners = () => {
        if(this._customListener(null)==null){
            return
        }
        if(this._scrollRef == null){
            return
        }
        this._scrollRef.removeEventListener('scroll', this._customListener)
    }

    scroll = (y: number, x: number = 0) => {
        try{
            if(this._scrollRef === null){
                return console.log(`can't scroll now`);
            }
            this._scrollRef.scrollTo(x, y)
        } catch(e){
            console.log('scroll ex', e)
        }
    }
}

export { ScrollModel }