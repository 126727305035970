import { UPDATE } from "../../DataTypes/UPDATE"
import { RW } from "../../Helpers/LangHelper"
import { UpdateComponent } from "./UpdateComponent"

type langProps = {
    for: string;    
    c?:boolean
}

class LangItem extends UpdateComponent {
    props: langProps
    constructor(props: langProps){
        super(props)
        this.type = UPDATE.LANG
        this.props = props
    }
    render() {
        // console.log(this.props.for, RW(this.props.for))
        return <>{typeof RW(this.props.for) === "string" ? RW(this.props.for):this.props.for}</>
    }
}

export { LangItem }