import { seo } from "../SeoController";


class PageModel {
    private _pageName:string;

    constructor(pageName: string){
        this._pageName = pageName
        this.pageMounted = this.pageMounted.bind(this)
    }

    get pageName(){
        return this._pageName
    }

    pageMounted(){
        seo().title(this._pageName)
        // controllers().menu.page(this._pageName)
    }
}

export { PageModel }