import { Lang } from "./Lang"


class English extends Lang {

    constructor(){
        super("en")
        this.vals = [
            // default entries
            {"": ""},
            
        ]
    }
}

export { English }