import { AppSettings } from "../Constants/AppSettings"
import { capitalize, decodeSimplyName } from "../Helpers/Helpers"


class SeoController {

    constructor(){
        this.makeDefaultTitle()
    }

    makeDefaultTitle = () => {
        // console.log("MY TITLE WAS SET");

        const pathname = window.location.pathname
        if(!pathname){
            // console.error("NO PATHNAME")
            return
        }
        if(pathname.indexOf("iptv")!==-1){
            // console.log("pathname", pathname)
            const channelName = pathname.replace('/iptv/', '')
            this.title(`${decodeSimplyName(channelName)} watch on`)
            this.description(`Best show ${decodeSimplyName(channelName)}`)
        }
        if(pathname.indexOf("movie")!==-1){
            const channelName = pathname.replace('/watch/movie/', '')
            this.title(`${decodeSimplyName(channelName)} watch on`)
            this.description(`${decodeSimplyName(channelName)} movie on`)
        }
        if(pathname.indexOf("serie")!==-1){
            const channelName = pathname.replace('/watch/serie/', '')
            this.title(`${decodeSimplyName(channelName)} watch on`)
            this.description(`${decodeSimplyName(channelName)} series on`)
        }
        // this.title("BOBER TV");
    }

    title = (title: string) => {
        try {
            if(typeof title === "string"){
                document.title = `${capitalize(title)} ${AppSettings.sitename}`
            } else {
                document.title = `${AppSettings.sitename}`
            }
        } catch(e){
            console.error(e)
        }
    }

    description = (description: string ) => {
        try {
            var descriptionNew = `${AppSettings.sitename}`
            if(typeof description === "string"){
                descriptionNew = `${capitalize(description)} ${AppSettings.sitename}`
            }
            var meta=document.getElementsByTagName("meta");
            for (var i=0; i<meta.length; i++) {
                if (meta[i].name.toLowerCase()==="description") {
                    meta[i].content=descriptionNew
                }
            }
        } catch(e){
            console.error(e)
        }
    }

    url = (urlpath: string) => {
        try{
            if(window.history){
                window.history.replaceState(null, AppSettings.sitename, urlpath)
            } else {
                console.error("NO window.history")
            }
        } catch(e){

        }
    }
}


// @ts-ignore
global.__app__ = global.__app__ || {};
// @ts-ignore
global.__app__.seo =
  // @ts-ignore
  global.__app__.seo || new SeoController();

export function seo(): SeoController {
  // @ts-ignore
  return global.__app__.seo;
}