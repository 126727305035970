import { MainScroll } from "./MainScroll"


class AppController {
    private _mainScroll:MainScroll;

    constructor(){
        this._mainScroll = new MainScroll()
    }

    get mainScroll(){
        return this._mainScroll
    }
}

export { AppController }