import { controllers } from "../Controllers"
import { navigator } from "../Navigator"


export type menuItemType = {
    title: string;
    link: string;
    icon?: any;
    name: string;
    authItem?: boolean;
    new?: boolean;
}

class MenuItemModel {
    private _model: menuItemType
    private _selected: boolean
    private _ref: any
    private _authItem: boolean
    
    constructor(model: menuItemType){
        this._model = model;
        this._selected = false
        this._authItem = model.authItem || false;
        this.setRef = this.setRef.bind(this)
        this.click = this.click.bind(this)
    }
    
    get new(){
        return this._model.new
    }

    get authItem(){
        return this._authItem
    }

    setRef(ref: any){
        this._ref = ref
    }

    get name(){
        return this._model.name
    }

    get title(){
        return this._model.title
    }

    get link(){
        return this._model.link
    }

    get icon(){
        return this._model.icon
    }

    get selected(){
        return this._selected
    }

    setSelected(bool: boolean){
        if(bool !== this._selected){
            this._selected = bool
            this._ref.setSelected(bool)
        }
    }

    click(){
        navigator().navigate(this.link)
        controllers().menu.removeSelected()
        this.setSelected(true)
    }
}

export { MenuItemModel }