export type UPDATETYPE = "lang"|"loader"|"auth"|"alert"|"menu"|"userinfo"|"dictionary";

export const UPDATE = {
    LANG: "lang",
    LOADER: "loader",
    AUTH: "auth",
    ALERT: "alert",
    MENU: "menu",
    USERINFO: "userinfo",
    DICTIONARY: "dictionary"
}