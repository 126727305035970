
class Model {
    private _ref: any;

    constructor(){
        this._ref = null
    }

    set = (ref: any) => {
        this._ref = ref
    }

    updateMe = () => {
        if(this._ref === null){
            return
        }
        try{
            this._ref.update()
        } catch(e){
            console.log('ref doesnt set', e)
        }
    }

    get ref(){
        return this._ref
    }

}

export { Model }