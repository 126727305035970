

export const capitalize = (string: any) => {
    if(!string){
        return ''
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const slised = (string: string|undefined, maxlength: number) => {
  if(typeof string !== "string"){
    return string
  }
  return string.slice(0, maxlength)+`${string.length>maxlength ? '...' : ''}`
}


export const pronunciation = (count: number, txtArr: Array<string>) => {
  let cases = [2, 0, 1, 1, 1, 2];
  return txtArr[count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]];
}


export const timeToFormat = (time: number) => {
  if(typeof time !== 'number'){
      return time
  }
  if(time<60){
      return getSmallTime(time)
  } else if(time<3600){
      return getMiddleTime(time)
  } else {
      return getBigTime(time)
  }
}

const getSmallTime = (time: number) => {
  return `00:00:${tb(time)}`;
}

const getMiddleTime = (time: number) => {
  const minutes = Math.floor(time/60);
  const seconds = time - minutes * 60;
  return `00:${tb(minutes)}:${tb(seconds)}`
}

const getBigTime = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours*3600)/60);
  const seconds = time - minutes * 60 - hours*3600;
  return `${tb(hours)}:${tb(minutes)}:${tb(seconds)}`
}

//time beautify
const tb = (val: number) => {
  val = Math.round(val)
  return val<10 ? `0${val}`:`${val}`
}


export const makeSimplyName = (name: any) => {
  if(typeof name !== "string"){
    return name
  }
  // console.log("name", name)
  const replaced = replaceStringAll(name,[[' ','_'],[',', '%2C'],['&','%26'],['!','%21']])
  // console.log("name replaced", replaced)
  return replaced
}

export const decodeSimplyName = (name: any) => {
  if(typeof name !== "string"){
    return name
  }
  return replaceString(name,'_', ' ')
}

export const replaceStringAll = (str:string, inputs: Array<any>) => {
  try{
    var retString = str
    inputs.forEach(inp=> {
      retString = replaceString(retString,inp[0],inp[1])
    })
    return retString
  } catch(e){
    return str
  }
}

export const replaceString = (str: string, search: string, replace: string) => {
  if(typeof str !== "string"){
    return str
  }
  if(typeof str.replaceAll === "function"){
    return str.replaceAll(search, replace)
  } else {
    return replaceES5(str,search,replace)
  }
}

export const replaceES5 = (s: string, e: string, r: string) => {
    var replaced = s
    while(replaced.indexOf(e)!==-1){
        replaced = replaced.replace(e,r)
    }
    return replaced
}


export const stringParser = (strnum: string|number): number => {
  return typeof strnum === "string" ? parseInt(strnum) : strnum
}